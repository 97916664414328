import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import {
	AlarisButtonComponent,
	AlarisIconDirective,
	AlarisProfileService,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { TranslateModule } from '@ngx-translate/core';


type EmptyTable =
	'edr-export'
	| 'edr-export-tasks'
	| 'contacts'
	| 'stop-list'
	| 'imports'
	| 'groups'
	| 'cw-senders'
	| 'senders'
	| 'templates'
	| 'templates-with-filter'
	| 'campaigns-list'
	| 'campaign-loading'
	| 'packages'
	| 'plans'
	| 'cw-plans-packages'
	| 'cw-senders-plans-packages'
	| 'invoices'
	| 'api-keys'
	| 'short-links'
	| 'archived-short-links'
	| 'active-short-links'

@Component({
	selector: 'app-empty-table',
	templateUrl: './empty-table.component.html',
	styleUrls: ['./empty-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		TranslateModule,
		AlarisIconDirective,
		AlarisButtonComponent
	],
	standalone: true
})
export class EmptyTableComponent implements OnChanges {

	@Input() type: EmptyTable = 'contacts';
	@Output() readonly newContact = new EventEmitter();
	@Output() readonly newGroup = new EventEmitter();
	@Output() readonly newStopList = new EventEmitter();
	@Output() readonly newImport = new EventEmitter();
	@Output() readonly senderRequest = new EventEmitter();
	@Output() readonly newTemplate = new EventEmitter();
	@Output() readonly clearFilter = new EventEmitter();
	@Output() readonly newCampaign = new EventEmitter();
	@Output() readonly packageRequest = new EventEmitter();
	@Output() readonly planRequest = new EventEmitter();
	@Output() readonly financeTransition = new EventEmitter();
	@Output() readonly newCampaignFromFile = new EventEmitter();
	@Output() readonly newKey = new EventEmitter();

	title = '';
	subTitle = '';
	buttonText = '';
	additionalButtonText = '';
	icon = '';
	additionalIcon = '';
	disabled = false;

	constructor(@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService) {
	}

	allowed(p: CP_PERMISSIONS | CP_PERMISSIONS[]): boolean {
		return this.profile.allowed([p].flat());
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ( changes.type ) {
			switch (this.type) {
				case 'contacts':
					this.title = 'contacts.noContactsFound';
					this.subTitle = 'contacts.noContactsFoundNote';
					this.buttonText = 'actions.importContacts';
					this.icon = 'icon-import';
					this.additionalButtonText = 'contacts.addContact';
					this.additionalIcon = 'icon-plus';
					this.disabled = !this.allowed(CP_PERMISSIONS.CONTACTS_E);
					break;
				case 'groups':
					this.title = 'contacts.noGroupFound';
					this.subTitle = 'contacts.noGroupFoundNote';
					this.buttonText = 'contacts.addGroup';
					this.icon = 'icon-plus';
					this.disabled = !this.allowed(CP_PERMISSIONS.CONTACTS_E);
					break;
				case 'imports':
					this.title = 'contacts.noFilesImported';
					this.subTitle = 'contacts.noFilesImportedNote';
					this.buttonText = 'actions.importContacts';
					this.icon = 'icon-import';
					this.disabled = !this.allowed(CP_PERMISSIONS.CONTACTS_E);
					break;
				case 'stop-list':
					this.title = 'contacts.noContactsInStopList';
					this.subTitle = 'contacts.noContactsInStopListNote';
					this.buttonText = 'contacts.goToContacts';
					this.icon = 'icon-arrow-left';
					this.disabled = !this.allowed(CP_PERMISSIONS.CAMPAIGNS_R);
					break;
				case 'senders':
				case 'cw-senders':
					this.title = 'senderIds.noSenderIdsFound';
					if ( this.allowed([CP_PERMISSIONS.SENDERS_R, CP_PERMISSIONS.SENDERS_E]) ) {
						this.subTitle = this.type === 'senders' ?
							'senderIds.startByClicking': 'campaigns.noSenderIdsFound';
						this.buttonText = 'senderIds.requestSenderId';
						this.icon = 'icon-plus';
					}
					this.disabled = !this.allowed([CP_PERMISSIONS.SENDERS_R, CP_PERMISSIONS.SENDERS_E]);
					break;
				case 'templates':
					this.title = 'templates.noTemplatesFound';
					this.subTitle = 'templates.startCreateTemplate';
					this.buttonText = 'templates.createTemplate';
					this.icon = 'icon-plus';
					this.disabled = !this.allowed(CP_PERMISSIONS.MESSAGE_TEMPLATES_E);
					break;
				case 'templates-with-filter':
					this.title = 'templates.noResultForSenderId';
					this.subTitle = '';
					this.buttonText = 'gl.clearFilter';
					this.icon = 'icon-close';
					break;
				case 'campaigns-list':
					this.title = 'campaigns.noCampaignsFound';
					this.subTitle = 'campaigns.startSending';
					this.buttonText = 'campaigns.newCampaign';
					this.icon = 'icon-plus';
					this.additionalButtonText = 'campaigns.campaignFromFile';
					this.additionalIcon = 'icon-import';
					this.disabled = !this.allowed(CP_PERMISSIONS.CAMPAIGNS_E);
					break;
				case 'campaign-loading':
					this.title = 'campaigns.loadingData';
					break;
				case 'packages':
					this.title = 'purchase.noPackagesFound';
					this.subTitle = 'purchase.submitToUsingPackages';
					this.buttonText = 'purchase.createRequest';
					this.icon = 'icon-plus';
					this.disabled = !this.allowed(CP_PERMISSIONS.SUBSCR_E);
					break;
				case 'plans':
					this.title = 'purchase.noPlansFound';
					this.subTitle = 'purchase.submitToUsingPlans';
					this.buttonText = 'purchase.createRequest';
					this.icon = 'icon-plus';
					this.disabled = !this.allowed(CP_PERMISSIONS.SUBSCR_E);
					break;
				case 'invoices':
					this.title = 'invoices.noFound';
					this.subTitle = '';
					this.buttonText = '';
					this.icon = '';
					break;
				case 'edr-export':
					this.title = 'statistics.noEdrFound';
					this.subTitle = 'statistics.noEdrFoundNote';
					this.buttonText = '';
					this.icon = '';
					break;
				case 'edr-export-tasks':
					this.title = 'statistics.noEdrTaskFound';
					this.subTitle = 'statistics.noEdrTaskFoundNote';
					this.buttonText = '';
					this.icon = '';
					break;
				case 'cw-plans-packages':
					this.title = 'campaigns.noPlansAndPacksFound';
					if ( this.allowed(CP_PERMISSIONS.SUBSCR_R) ) {
						this.subTitle = 'campaigns.noPlansAndPacksNote';
						this.buttonText = 'campaigns.moveToPurchase';
						this.icon = 'icon-arrow-right';
					}
					break;
				case 'cw-senders-plans-packages':
					this.title = 'campaigns.noSenderIdsPlansPacksFound';
					this.subTitle = 'campaigns.noSenderIdsPlansPacksNote';
					this.buttonText = 'senderIds.requestSenderId';
					this.icon = 'icon-plus';
					this.additionalButtonText = 'campaigns.moveToPurchase';
					this.additionalIcon = 'icon-arrow-right';
					this.disabled = !this.allowed([CP_PERMISSIONS.SENDERS_R, CP_PERMISSIONS.SENDERS_E]);
					break;
				case 'api-keys':
					this.title = 'api.noApiKey';
					this.subTitle = 'api.noApiKeyNote';
					this.buttonText = 'api.add';
					this.icon = 'icon-plus';
					break;
				case 'short-links':
					this.title = 'urlShortener.noURLsFound';
					this.subTitle = 'urlShortener.startCreateURL';
					break;
				case 'archived-short-links':
					this.title = 'urlShortener.noURLsAndDomains';
					this.subTitle = 'urlShortener.noArchivedURLHint';
					break;
				case 'active-short-links':
					this.title = 'urlShortener.noURLsAndDomains';
					this.subTitle = 'urlShortener.noActiveURLHint';
					break;
				default:
					break;
			}
		}
	}

	clicked(): void {
		switch (this.type) {
			case 'contacts':
				this.newImport.emit(undefined);
				break;
			case 'groups':
				this.newGroup.emit(undefined);
				break;
			case 'imports':
				this.newImport.emit(undefined);
				break;
			case 'stop-list':
				this.newStopList.emit(undefined);
				break;
			case 'campaigns-list':
				this.newCampaign.emit(undefined);
				break;
			case 'senders':
			case 'cw-senders-plans-packages':
				this.senderRequest.emit(undefined);
				break;
			case 'templates':
				this.newTemplate.emit(undefined);
				break;
			case 'templates-with-filter':
				this.clearFilter.emit(undefined);
				break;
			case 'packages':
				this.packageRequest.emit(undefined);
				break;
			case 'plans':
				this.planRequest.emit(undefined);
				break;
			case 'cw-plans-packages':
				this.financeTransition.emit(undefined);
				break;
			case 'api-keys':
				this.newKey.emit(undefined);
				break;
			default:
				break;
		}
	}

	additionsClicked(): void {
		switch (this.type) {
			case 'contacts':
				this.newContact.emit(undefined);
				break;
			case 'cw-senders-plans-packages':
				this.financeTransition.emit(undefined);
				break;
			case 'campaigns-list':
				this.newCampaignFromFile.emit(undefined);
				break;
			default:
				break;
		}
	}
}
