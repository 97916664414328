<alaris-dialog [closeAction]="close.bind(this)"
			   [closeButtonText]="'gl.stayOnPage'"
			   [confirmAction]="confirm.bind(this)"
			   [confirmButtonText]="'gl.leaveWithoutSaving'">
	<ng-container slot="header">
		<h3>
			{{ "gl.leaveWithoutSaving" | translate }}?
		</h3>
	</ng-container>
	<ng-container slot="content">
		{{ 'gl.changesBeLost' | translate }}
	</ng-container>
</alaris-dialog>



