import { Injectable } from '@angular/core';
import { Campaign, CampaignChannelSettings, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import { TagMode } from '@campaign-portal/components-library/lib/tag/src/base';
import { TrafficType } from '@campaign-portal/namespace/common/enums';
import { MessageTemplatePipe, SenderPipe } from '@helpers/repo/repo.pipe';
import { AlarisProgressLineType } from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class CampaignUtilsService {

	constructor(
		private readonly senderPipe: SenderPipe,
		private readonly messagePipe: MessageTemplatePipe
	) {
	}

	selectStatusColor(status?: CampaignStatus): TagMode {
		const statusSet: Record<CampaignStatus, TagMode> = {
			[CampaignStatus.IN_PROGRESS]: 'info',
			[CampaignStatus.COMPLETED]: 'success',
			[CampaignStatus.FAILED]: 'error',
			[CampaignStatus.CANCELLED]: 'warning',
			[CampaignStatus.SCHEDULED]: 'gray',
			[CampaignStatus.PAUSED]: 'gray-light'
		};
		return statusSet[status ?? CampaignStatus.SCHEDULED];
	}

	// msToTime(duration: number): { seconds: number; minutes: number; hours: number } {
	// 	const seconds = Math.floor((duration / 1000) % 60);
	// 	const minutes = Math.floor((duration / (1000 * 60)) % 60);
	// 	const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
	// 	return { seconds, minutes, hours };
	// }

	selectCampaignProgressColor(campaign: Campaign): AlarisProgressLineType {
		switch (campaign.info.status) {
			case CampaignStatus.SCHEDULED:
				return 'secondary';
			case CampaignStatus.IN_PROGRESS:
				return 'info';
			case CampaignStatus.PAUSED:
				return 'secondary';
			case CampaignStatus.COMPLETED:
				return 'success';
			case CampaignStatus.FAILED:
				return 'success-negative';
			case CampaignStatus.CANCELLED:
				return 'warning';
			default:
				return 'secondary';
		}
	}

	calculatePercentage(campaign: Campaign): number {
		const messageCount = campaign.statistics?.messageCount ?? 0;
		const contactCount = campaign.statistics?.contactCount ?? 0;
		if ( contactCount === 0 ) {
			return 0;
		}

		const progress = (messageCount / contactCount) * 100;
		return Math.max(0, progress);
	}

	prepareTTL(campaign: Campaign, trafficType: TrafficType): { seconds: number } | null {
		const channels = campaign.setup.channels || [];
		const omni = channels.find(setting => setting.trafficType === trafficType)?.omni ?? {};
		if ( omni.ttl ) {
			return { seconds: omni.ttl };
			// return this.msToTime(omni.ttl);
		} else {
			return null;
		}
	}


	// todo - might be optimized with single call campaignSettings and return spread object { sender, message, etc }
	sender(campaign: Campaign, trafficType: TrafficType): string {
		const setup = this.campaignSettings(campaign, trafficType);
		return setup !== undefined ? this.senderPipe.transform(setup.sender)?.name ?? '' : '';
	}

	message(campaign: Campaign, trafficType: TrafficType): string {
		const setup = this.campaignSettings(campaign, trafficType);
		if ( setup !== undefined && setup.message?.text !== undefined ) {
			return setup.message.text;
		} else if ( setup !== undefined && setup.message?.templateID !== undefined ) {
			return this.messagePipe.transform(setup.message.templateID)?.messageTemplate ?? '';
		}
		return '';
	}

	imageUrl(campaign: Campaign, trafficType: TrafficType): string | undefined {
		const setup = this.campaignSettings(campaign, trafficType);
		return setup?.omni?.viberSettings?.imageUrl;
	}

	buttonCaption(campaign: Campaign, trafficType: TrafficType): string | undefined {
		const setup = this.campaignSettings(campaign, trafficType);
		return setup?.omni?.viberSettings?.buttonCaption;
	}

	buttonActionUrl(campaign: Campaign, trafficType: TrafficType): string | undefined {
		const setup = this.campaignSettings(campaign, trafficType);
		return setup?.omni?.viberSettings?.buttonActionUrl;
	}

	private campaignSettings(campaign: Campaign, trafficType: TrafficType): CampaignChannelSettings | undefined {
		return campaign.setup.channels.find((setting) => {
			return setting.trafficType === trafficType;
		});
	}
}
