import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AlarisDialogService } from '@campaign-portal/components-library';

import { LostDataDialogComponent } from '@helpers/shared/lost-data-dialog/lost-data-dialog.component';
import { BaseCanDeactivateComponent } from '@helpers/shared/can-deactivate/base-deactivate';
import { CanDeactivateFn } from '@angular/router';

// https://betterprogramming.pub/angular-how-keep-user-from-lost-his-data-by-accidentally-leaving-the-page-before-submit-4eeb74420f0d

@Injectable()
export class CanDeactivateGuardService {

	constructor(
		public readonly dialog: AlarisDialogService
	) {
	}

	canDeactivate(
		component: BaseCanDeactivateComponent
	): Observable<boolean> | boolean {
		if ( component.canDeactivate() ) {
			return true;
		}
		return this.lostDataDialog();
	}

	lostDataDialog(): Observable<boolean> {
		return this.dialog.open<boolean>(LostDataDialogComponent, { disableClose: false, autoFocus: false }).closed
			.pipe(map(result => !!result));
	}
}

export const CanDeactivateGuard: CanDeactivateFn<BaseCanDeactivateComponent> = (
	component: BaseCanDeactivateComponent
): Observable<boolean> | boolean => {
	return inject(CanDeactivateGuardService).canDeactivate(component);
};
