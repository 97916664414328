@if (loading$ | async) {
	<app-empty-table type="campaign-loading"></app-empty-table>
} @else {
	@if (hasSubscriptions && !hasSenders) {
		<app-empty-table (senderRequest)="senderRequest.emit()"
						 type="cw-senders">
		</app-empty-table>
	}
	@if (!hasSubscriptions && hasSenders) {
		<app-empty-table (financeTransition)="financeTransition.emit()"
						 type="cw-plans-packages">
		</app-empty-table>
	}
	@if (!hasSubscriptions && !hasSenders) {
		<app-empty-table (senderRequest)="senderRequest.emit()"
						 (financeTransition)="financeTransition.emit()"
						 type="cw-senders-plans-packages">
		</app-empty-table>
	}

}

