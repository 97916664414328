/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	forkJoin,
	map,
	Observable,
	Subject,
	takeUntil
} from 'rxjs';

import {
	ALARIS_PROPERTY_MATCHER,
	AlarisBalanceService,
	AlarisButtonComponent,
	AlarisCheckboxComponent,
	AlarisComplexTooltipDirective,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFadeDirective,
	AlarisFileLoaderComponent,
	AlarisFilesService,
	AlarisFilterPipe,
	AlarisIconDirective,
	AlarisInputComponent,
	AlarisLinkComponent,
	AlarisLoadingDirective,
	AlarisOptions2Component,
	AlarisParsedFileTableComponent,
	AlarisProfileService,
	AlarisRadioComponent,
	AlarisScheduledComponent,
	AlarisSelect2Component,
	AlarisSelectFilter2Component,
	AlarisTooltipDirective,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import {
	DecisionMode,
	EnabledDisabledStatus,
	MessagePurpose,
	TrafficType
} from '@campaign-portal/namespace/common/enums';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { ApproximateData } from '@campaign-portal/namespace/entities/campaigns/specs';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { FileRow, FilesReports } from '@campaign-portal/namespace/entities/files/specs';

import { ViberPriorityComponent } from '@helpers/components/viber-priority/viber-priority.component';
import { ChannelsService } from '@helpers/services/channels.service';
import { CampaignWizardService } from '../campaign-wizard/campaign-wizard.service';
import { SendersService } from '../services/senders.service';
import { SenderRequestComponent } from '../senders/sender-request/sender-request.component';
import {
	CampaignChannelSettingsControls,
	CampaignOmniSettingsControls,
	CampaignViberSettingsControls
} from '../shared/types/form-types';
import { buttonActionUrl, buttonCaption, imageUrl, imMessage, message, phone } from './fields';
import { CampaignsDialogComponent } from '../shared/dialog/campaigns-dialog.component';
import { CampaignsService } from '../services/campaigns.service';
import { PurchaseService } from '../../finance/purchase/purchase.service';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyCampaignCardComponent } from '../shared/empty-campaign-card/empty-campaign-card.component';
import { TuiLegendItem, TuiRingChart } from '@taiga-ui/addon-charts';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { FilterPipe } from '@helpers/pipes/filter.pipe';

@Component({
	selector: 'app-campaign-from-file',
	templateUrl: './campaign-from-file.component.html',
	styleUrls: ['./campaign-from-file.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisLoadingDirective,
		AlarisIconDirective,
		TranslateModule,
		EmptyCampaignCardComponent,
		AlarisFadeDirective,
		AlarisInputComponent,
		AlarisSelect2Component,
		AlarisOptions2Component,
		AlarisSelectFilter2Component,
		AlarisLinkComponent,
		AlarisButtonComponent,
		TuiLegendItem,
		TuiRingChart,
		CurrencyPipe,
		AlarisScheduledComponent,
		AlarisRadioComponent,
		AlarisComplexTooltipDirective,
		AsyncPipe,
		AlarisParsedFileTableComponent,
		AlarisFileLoaderComponent,
		ReactiveFormsModule,
		FilterPipe,
		AlarisFilterPipe,
		AlarisCheckboxComponent,
		AlarisTooltipDirective
	],
	standalone: true
})
export class CampaignFromFileComponent implements OnInit, OnDestroy {

	readonly TrafficType = TrafficType;
	readonly MessagePurpose = MessagePurpose;
	readonly tooltipPriorityComponent = ViberPriorityComponent;

	hasSubscriptions = false;
	hasSenders = false;

	fileRows: FileRow[] = [];
	totalRecipients = 0;

	senders: SenderSubscription<exist>[] = [];
	readonly filterSenderIdsControl = new FormControl('');
	readonly sendersMatcher = ALARIS_PROPERTY_MATCHER<SenderSubscription<exist>>('name');
	readonly scheduledChoiceControl = new FormControl<'now' | 'future'>('now', { nonNullable: true });


	approximateCampaignData: ApproximateData | null = null;
	approximateFileAnalysis: FilesReports | null = null;

	readonly fileLoading$ = new BehaviorSubject<boolean>(false);
	readonly analysis$ = new BehaviorSubject<boolean>(false);
	fields = [phone, message];

	loading$ = new Observable<boolean>();
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profileService: AlarisProfileService,
		public readonly cw: CampaignWizardService,
		public readonly sendersService: SendersService,
		public readonly channelsService: ChannelsService,
		public readonly bs: AlarisBalanceService,
		private readonly campaignsService: CampaignsService,
		private readonly filesService: AlarisFilesService,
		private readonly purchaseService: PurchaseService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly dialog: AlarisDialogService,
		private readonly cd: ChangeDetectorRef,
		private readonly router: Router
	) {
	}

	get channelControls(): CampaignChannelSettingsControls {
		return this.cw.setupControls.channels.at(0).controls;
	}

	get templateRef(): string {
		switch (this.channelControls.trafficType.value) {
			case TrafficType.SMS:
				return 'assets/templates/campaign_template_sms.xlsx';
			case TrafficType.VIBER:
				return 'assets/templates/campaign_template_viber.xlsx';
			default:
				return 'assets/templates/campaign_template.xlsx';
		}
	}

	get createCampaignDisabled(): boolean {
		const invalid = this.cw.form.invalid;
		const nophone = !(this.cw.setupControls.file.controls.columns.value?.find((order) => {
			return order.columnName === phone.variable;
		}));
		const nomessage = !(this.channelControls.trafficType.value === TrafficType.SMS ?
			this.cw.setupControls.file.controls.columns.value?.find((order) => {
				return order.columnName === message.variable;
			}) :
			this.cw.setupControls.file.controls.columns.value?.find((order) => {
				return order.columnName === imMessage.variable;
			}));
		const noCorrectRow = this.approximateFileAnalysis !== null && !this.approximateFileAnalysis?.correctRow;
		const noRecipients = this.approximateCampaignData !== null && !this.approximateCampaignData?.recipients;

		return invalid || nophone || nomessage || noCorrectRow || noRecipients;
	}

	ngOnInit(): void {
		this.cw.create('file');
		this.cw.setupControls.channels.push(new FormGroup<CampaignChannelSettingsControls>({
			sender: new FormControl<Id<exist> | null>(
				null,
				{ validators: Validators.required }),
			trafficType: new FormControl<TrafficType>(
				TrafficType.SMS,
				{ nonNullable: true, validators: Validators.required })
		}));
		this.prepareSenders(TrafficType.SMS);

		this.channelControls.trafficType.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((value) => {
				if ( value === TrafficType.SMS ) {
					if ( this.channelControls.omni ) {
						this.cw.setupControls.channels.at(0).removeControl('omni');
					}
					this.fields = [phone, message];
				} else if ( value === TrafficType.VIBER ) {
					this.fields = [phone, imMessage, buttonActionUrl, imageUrl, buttonCaption];
					this.cw.setupControls.channels.at(0).addControl(
						'omni',
						new FormGroup<CampaignOmniSettingsControls>({
							fallbackType: new FormControl<DecisionMode>(
								DecisionMode.BY_SUBMITTED,
								{ nonNullable: true }),
							viberSettings: new FormGroup<CampaignViberSettingsControls>({
								buttonActionUrl: new FormControl<string>('', { nonNullable: true }),
								imageUrl: new FormControl<string>('', { nonNullable: true }),
								buttonCaption: new FormControl<string>('', { nonNullable: true }),
								messagePurpose: new FormControl<MessagePurpose>(MessagePurpose.TRANSACTION, {
									nonNullable: true,
									validators: Validators.required
								})
							})
						})
					);
				} else {
					this.fields = [phone, message, imMessage, buttonActionUrl, imageUrl, buttonCaption];
				}
				this.prepareSenders(value);
				this.channelControls.sender.reset();
			});

		this.purchaseService.subscribed()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((subscribed => {
				this.hasSubscriptions = !!(subscribed.Data.packRecords.size || subscribed.Data.plans.length);
				this.cd.markForCheck();
			}));

		this.loading$ = combineLatest([
			this.sendersService.loading$, this.purchaseService.loadingSubscribed$
		])
			.pipe(
				map(([ssLoading, psLoading]: boolean[]) => {
					if ( !ssLoading ) {
						this.hasSenders = this.sendersService.active$.getValue().length > 0;
					}
					this.cd.detectChanges();
					return ssLoading || psLoading;
				}),
				distinctUntilChanged(),
				takeUntil(this.ngUnsubscribe)
			);

		this.cw.form.valueChanges
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				() => this.resetAnalysis()
			);
	}

	ngOnDestroy(): void {
		this.fileLoading$.complete();
		this.analysis$?.complete();
		this.cw.reset();
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	openSenderIdRequestPanel(): void {
		this.editPanel.close();
		this.editPanel.open(SenderRequestComponent, EditPanelWidth.SM, {});
	}

	displaySender(id: Id<exist>): string {
		return this.sendersService.map.get(id)?.name ?? '';
	}

	addFiles($event: (FileInfo | undefined)[]): void {
		if ( $event[0] ) {
			this.fileLoading$.next(true);
			const file = $event[0];
			this.cw.setupControls.file.controls.file.setValue(file);
			this.filesService.parse(file.id)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe((resp) => {
					this.fileRows = resp.Data;
					this.totalRecipients = resp.Total;
					this.fileLoading$.next(false);
				});
		} else {
			this.totalRecipients = 0;
			this.fileRows = [];
			this.cw.setupControls.file.controls.file.setValue(null);
			this.cw.setupControls.file.controls.columns.setValue([]);
			this.resetAnalysis();
		}
	}

	resetAnalysis(): void {
		this.approximateCampaignData = null;
		this.approximateFileAnalysis = null;
	}

	createCampaign(): void {
		this.dialog.open(CampaignsDialogComponent, {
			data: {
				campaign: this.cw.prepare('file'),
				type: 'Captcha',
				total: this.approximateCampaignData?.recipients || this.totalRecipients
			},
			autoFocus: false
		}).closed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((v) => {
				if ( v ) {
					this.editPanel.close();
				}
			});
	}

	campaignDetails(): void {
		this.analysis$.next(true);
		forkJoin([
			this.campaignsService.approximate(this.cw.prepare('file')),
			this.filesService.analysis({
				Data: {
					fileId: this.cw.setupControls.file.controls.file.value!.id,
					columnName: phone.variable,
					format: 'number',
					columns: this.cw.setupControls.file.controls.columns.value
				}
			})
		])
			.pipe((takeUntil(this.ngUnsubscribe)))
			.subscribe((resp) => {
				this.approximateCampaignData = resp[0].Data;
				this.approximateFileAnalysis = resp[1].Data;
				this.analysis$?.next(false);
				this.cd.detectChanges();
			});
	}

	moveToPurchase(): void {
		this.editPanel.close();
		this.router.navigate(['/purchase']);
	}

	private prepareSenders(value: TrafficType): void {
		this.filterSenderIdsControl.reset();
		this.senders = this.sendersService.list.filter(
			sender => sender.trafficType === value && sender.enabled === EnabledDisabledStatus.ENABLED
		);
	}
}
