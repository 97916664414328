<alaris-dialog [closeAction]="close.bind(this)"
			   [closeButtonText]="'actions.cancel'"
			   [confirmAction]="confirm.bind(this)"
			   [confirmButtonText]="confirmButtonText"
			   [disabled]="disabled"
			   [loading$]="loading$" class="alaris-dialog">

	<ng-container slot="header">
		<h3 class="title">
			@switch (data.type) {
				@case ('PackageRequest') {
					{{ "purchase.packageRequestTitle" | translate }}
				}
				@case ('PlanRequest') {
					{{ "purchase.planRequestTitle" | translate }}
				}
				@case ('SelectPlan') {
					{{ "purchase.yourOrder" | translate }}
				}
				@case ('ShopNow') {
					{{ "purchase.yourOrder" | translate }}
				}
			}
		</h3>
	</ng-container>

	<ng-container slot="content">
		@switch (data.type) {
			@case ('PackageRequest') {
				<div class="w-100">
					{{ "purchase.packageRequestText" | translate }}
					<p>{{ "purchase.leaveComment" | translate }}</p>
					<alaris-text-area [formControl]="comment" [required]="true" data-testid="commentTextarea">
						{{ 'gl.comment' | translate }}
					</alaris-text-area>
				</div>
			}
			@case ('PlanRequest') {
				<div class="w-100">
					{{ "purchase.planRequestText" | translate }}
					<p>{{ "purchase.leaveComment" | translate }}</p>
					<alaris-text-area [formControl]="comment" [required]="true" data-testid="commentTextarea">
						{{ 'gl.comment' | translate }}
					</alaris-text-area>
				</div>
			}
			@case ('SelectPlan') {
				<div class="card">
					<div class="name">
						<alaris-icon [name]="cu.icon(plan.trafficType)" class="channel-icon"></alaris-icon>
						{{ plan.name }}
					</div>
					<ul class="details">
						<li class="row">
							<span class="row__title">{{ 'purchase.channel' | translate }}</span>
							<ng-container *ngTemplateOutlet="channel; context: {$implicit: plan}"></ng-container>
						</li>
						<li class="row">
							<span class="row__title">{{ 'purchase.country' | translate }}</span>
							<b class="countries">
								<span class="text-truncate">{{ plan.countries!.slice(0, 2).join(', ') }}</span>
								@if (plan.countries!.length > 2) {
									<alaris-tag (click)="seeAllCountries()"
												[alarisTooltip]="'purchase.allCountryHint' | translate"
												[rounded]="true" style="transform: scale(.75); cursor: pointer">
										+{{ plan.countries!.length - 2 }}
									</alaris-tag>
								}
							</b>
						</li>
						<li class="row">
							<span class="row__title">{{ 'purchase.billingMode' | translate }}</span>
							<b>
								{{ 'enums.' + plan.billingMode | translate }}
							</b>
						</li>
					</ul>
				</div>
				<alaris-alert class="plan-alert">
					{{ 'purchase.orderNote' | translate }}
				</alaris-alert>
			}
			@case ('ShopNow') {
				<div class="card">
					<div class="name">
						<alaris-icon [name]="cu.icon(package.trafficType)" class="channel-icon"></alaris-icon>
						{{ package.name }}
					</div>
					<ul class="details">
						<li class="row">
							<span class="row__title">{{ 'purchase.channel' | translate }}</span>
							<ng-container *ngTemplateOutlet="channel; context: {$implicit: package}"></ng-container>
						</li>
						<li class="row">
							<span class="row__title">{{ 'purchase.billingMode' | translate }}</span>
							<b>
								{{ 'enums.' + package.billingMode | translate }}
							</b>
						</li>
						<li class="row">
							<span class="row__title">{{ 'purchase.smsCost' | translate }}</span>
							<b>
								{{
									(package.packSettings.packPrice / package.messagesTotal)
										| currency: bs.currencyCode : 'symbol' : '1.2-5'
								}}
							</b>
						</li>
						<li class="row">
							<span class="row__title">{{ 'purchase.smsVolume' | translate }}</span>
							<b>
								{{ package.messagesTotal }}
							</b>
						</li>
						<li class="row">
							<span class="row__title">{{ 'purchase.validityPeriod' | translate }}</span>
							<b>
								{{ purchaseService.packActiveTillDate(package)  | timeAgo:'':true | noValue }}
							</b>
						</li>
						<li class="total row">
							<b>{{ 'campaigns.totalCost' | translate }}</b>
							<b>
								{{ package.packSettings.packPrice | currency: bs.currencyCode : 'symbol' : '1.2-5' }}
							</b>
						</li>
					</ul>
				</div>

				<app-package-country-network-list [package]="package" class="countries-networks">
				</app-package-country-network-list>

				@if (!positiveBalance) {
					<div class="top-up-alert">
						<alaris-icon name="icon-info"></alaris-icon>
						<div>
							{{ 'purchase.noMoney' | translate }}
							<alaris-link (click)="topUp()" [underline]="false"
										 data-testid="topUpBalanceLink" type="accent">
								{{ 'purchase.topUp' | translate }}
							</alaris-link>
							{{ 'purchase.yourBalance' | translate }}
						</div>
					</div>
				}
			}
		}
	</ng-container>
</alaris-dialog>

<ng-template #channel let-subscription>
	<b>
		{{
			cu.purpose(
				subscription.trafficType,
				subscription.settings?.viberSettings?.messagePurpose
			) | translate
		}}
	</b>
</ng-template>
